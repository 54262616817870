<script setup lang="ts">
    import type { ContentCarouselData } from '~/@types/cms';
    import type { IWidgetProps } from '~/composables/widgets';

    /**
     * Products Widget Container
     * loads a collection via the api and renders a set of cards for each product
     * while loading the collection, a skeleton should be rendered
     * we only fetch the first collection in the array
     */
    const props = defineProps<IWidgetProps<ContentCarouselData>>();
    const appStore = useAppStore();
    const { data, getValueSequence } = useWidget(props);

    const linkUrl = computed(() => {
        const t = data?.link?.data;
        if (t) {
            if (typeof t === 'string') {
                const data = appStore.getReference(t, 'links');
                if (data) {
                    return data.url.url;
                }
            }
        }
        return undefined;
    });
    const value = computed(() => {
        return getValueSequence(data.value);
    });
</script>
<template>
    <org-carousel
        v-if="value"
        :content-data="value"
        :link="linkUrl"
        :link-text="data?.link?.label"
        :title="data?.intro?.title"
        :background-color="data?.style?.background_color"
        :description="data?.intro?.subtitle">
    </org-carousel>
</template>
